@font-face {
    font-family: "Against";
    src: local("AgainstRegular"),
        url("../font/against.ttf") format("truetype");
}

@font-face {
    font-family: "Augusta";
    src: local("AugustaRegular"),
        url("../font/augusta.ttf") format("truetype");
}

@font-face {
    font-family: "JMH";
    src: local("JMHThin"),
        url("../font/JMH.ttf") format("truetype");
}

/* body {
    font-family: Raleway;
    margin: 0;
    padding: 0;
    min-height: 100vh;
} */


#header {
    background-color: #074500;
    color: white;
    padding: 0.7rem;
    display: flex;
    align-items: center;
}

.video-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place it behind other content */
    overflow: hidden;
}

.bg-video {
    position: absolute;
    bottom: 0; /* Align the video to the bottom of the container */
    left: 50%;
    transform: translateX(-50%); /* Center the video horizontally */
    width: 100vw;
    height: 56.25vw; /* 16:9 ratio */
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    pointer-events: none;
    user-select: none;
}

.homebutton {
    font-family: Against;
    background-color: transparent;
    border: 0.3rem solid transparent;
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    color: white;
}

.homebutton:hover {
    color: #ff7b1c;
}

.navbutton {
    font-family: Against;
    font-size: 1.3rem;
    padding: 0.7rem;
    margin-left: 1rem;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.navbutton::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.2rem;
    bottom: 0;
    left: 0;
    background-color: #ff7b1c;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.navbutton:hover {
    color: #ff7b1c;
}

.navbutton:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.greeting {
    /* padding: 25vh; */
    font-family: Against;
    /* font-size: 4rem; */
    color: white;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.nf {
    margin: 0rem;
    font-family: Against;
    color: #074500;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.intro {
    font-family: JMH;
    font-size: 1.5rem;
    padding: 1rem;
}

.resources {
    font-family: Against;
    font-size: 3rem;
    margin: 4rem;
    color: #074500;
}

.resourcesmob {
    font-family: Against;
    font-size: 1rem;
    margin: 2rem;
    color: #074500;
}

.committeetab {
    width: 1000px;
    /* background-color: white; */
    color: #ff7b1c;
}